import styled from 'styled-components';
import Heading from '@atoms/Heading/Heading';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';
import Select from '@molecules/Select/Select';
import Option from '@molecules/Select/Option';

export const StyledAddForm = styled.div`
  width: 100%;
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${rem(4)};
  text-align: center;
  line-height: 1;
`;

export const StyledSelect = styled(Select)`
  margin-top: ${rem(16)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${rem(20)};
`;

export const StyledSelectOption = styled(Option)`
  width: ${rem(192)};
`;
