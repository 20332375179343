import { StyledNewListButton, StyledNewListButtonIcon } from './NewListButton.styles';
import Add from '@icons/add.svg';

interface Props {
  label: string;
  onClick: () => void;
}

const NewListButton = ({ label, onClick }: Props) => {
  return (
    <StyledNewListButton type="button" onClick={onClick}>
      {label}
      <StyledNewListButtonIcon svg={Add} size={16} color="highlight" />
    </StyledNewListButton>
  );
};

export default NewListButton;
