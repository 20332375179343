import styled, { css } from 'styled-components';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledAddToList = styled.div<{ hasText: boolean }>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.hasText &&
    css`
      align-items: center;
    `}
`;

export const StyledButtonText = styled.span`
  margin-left: ${rem(8)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  &:disabled {
    svg,
    path {
      fill: ${variables.colors.darkerGray};
    }
  }
`;

export const StyledErrorText = styled(Text)`
  margin-top: ${rem(8)};
`;
