import variables from '@styles/variables';
import { rem } from 'polished';
import styled from 'styled-components';
import Icon from '@atoms/Icon/Icon';

export const StyledNewListButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  font-family: inherit;
  font-size: 100%;
  font: inherit;
  line-height: 1.15;
  overflow: visible;
  text-transform: none;
  border: none;
  margin: 0;
  padding: 0;
  user-select: none;
  color: ${variables.colors.highlight};
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: ${variables.fonts.fontGothamMedium};
  text-transform: uppercase;
  font-size: ${rem(14)};
`;

export const StyledNewListButtonIcon = styled(Icon)`
  margin-left: auto;
`;
