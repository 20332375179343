const resizeListener = {
  set: (onChangeHandler: () => void) => {
    window.addEventListener('resize', onChangeHandler);
  },

  remove: (onChangeHandler: () => void) => {
    window.removeEventListener('resize', onChangeHandler);
  },
};

export default resizeListener;
