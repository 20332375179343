import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import NewListButton from '@molecules/NewListButton/NewListButton';
import SelectBox from '@molecules/Select/SelectBox';
import { StyledAddForm, StyledButton, StyledHeading, StyledSelect, StyledSelectOption } from './AddForm.styles';
import { FormMode } from './types';

interface Props {
  onAddToList: (option: OptionType) => void;
  changeFormMode: (mode: FormMode) => void;
  isSaving: boolean;
  options: OptionType[];
}

const AddForm = ({ onAddToList, changeFormMode, isSaving, options }: Props) => {
  const { t } = useTranslation('addToList');

  useEffect(() => {
    // Update currentOption if options change and currentOption has no value
    if (!currentOption && options.length > 0) {
      setCurrentOption(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const [currentOption, setCurrentOption] = useState<OptionType>(options[0]);

  const onChangeHandler = (option: OptionType) => {
    setCurrentOption(option);
  };

  const onAddToListHandler = () => {
    onAddToList(currentOption);
  };

  const selectBoxFragment = ({ currentSelection, isOpen }: { currentSelection: OptionType; isOpen: boolean }) =>
    isOpen ? (
      <NewListButton label={t('addToList->new->label')} onClick={() => changeFormMode('NEW')} />
    ) : (
      <SelectBox currentSelection={currentSelection} />
    );

  const optionFragment = ({
    option,
    isSelected,
    onItemClickHandler,
  }: {
    option: OptionType;
    isSelected: boolean;
    onItemClickHandler: (option: OptionType) => void;
  }) => (
    <StyledSelectOption key={option.value} isSelected={isSelected} option={option} onItemClick={onItemClickHandler} />
  );

  return (
    <StyledAddForm>
      <StyledHeading variant="h3" label>
        {t('addToList->heading->unsaved')}
      </StyledHeading>

      {options.length > 0 && (
        <>
          <StyledSelect
            avoidViewport={false}
            onChange={onChangeHandler}
            options={options}
            selectBoxComponent={selectBoxFragment}
            optionComponent={optionFragment}
            isAddToList
            testId="addtolistdropdown-select"
          />

          <StyledButton
            onClick={onAddToListHandler}
            theme="primary"
            type="button"
            data-testid="add-to-list-add-form-button"
            isSubmitting={isSaving}
          >
            {t('addToList->button->unsaved')}
          </StyledButton>
        </>
      )}
    </StyledAddForm>
  );
};

export default AddForm;
