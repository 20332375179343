import styled from 'styled-components';
import Icon from '@atoms/Icon/Icon';
import Heading from '@atoms/Heading/Heading';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledCompleteForm = styled.div`
  width: 100%;
`;
export const StyledHeading = styled(Heading)`
  margin-top: ${rem(4)};
  text-align: center;
  line-height: 1;
`;

export const StyledCompleteWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  color: ${variables.colors.lighterBlack};
  width: 100%;
  margin-top: ${rem(16)};
  height: ${rem(52)};
  padding: ${rem(10)} ${rem(15)};
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(2)};
`;

export const StyledCompleteIcon = styled(Icon)`
  margin-left: auto;
`;

export const StyledCompleteButton = styled(Button)`
  width: 100%;
  margin-top: ${rem(20)};
`;

export const StyledCompleteText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
