import { ChangeEvent, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { StyledButton, StyledHeading, StyledInputField } from './CreateForm.styles';

interface Props {
  onCreateNewList: (input: string) => void;
  isSaving: boolean;
}

const CreateForm = ({ onCreateNewList, isSaving }: Props) => {
  const { t } = useTranslation('addToList');

  const [inputValue, setInputValue] = useState('');

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onCreateNewListHandler = () => {
    if (inputValue.trim()) {
      onCreateNewList(inputValue);
    }
  };

  const isBtnDisabled = !inputValue.trim() || isSaving;

  return (
    <div>
      <StyledHeading variant="h3" label>
        {t('addToList->heading->new')}
      </StyledHeading>

      <StyledInputField
        name="new-list"
        type="text"
        placeholder={t('addToList->input->placeholder')}
        onChange={onChangeHandler}
        disabled={isSaving}
        aria-label={t('addToList->new->label')}
        value={inputValue}
      />

      <StyledButton
        disabled={isBtnDisabled}
        onClick={onCreateNewListHandler}
        theme="primary"
        type="button"
        data-testid="add-to-list-create-form-button"
        isSubmitting={isSaving}
      >
        {t('addToList->button->unsaved')}
      </StyledButton>
    </div>
  );
};

export default CreateForm;
