import styled from 'styled-components';
import InputField from '@atoms/InputField/InputField';
import Heading from '@atoms/Heading/Heading';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';

export const StyledHeading = styled(Heading)`
  margin-top: ${rem(4)};
  text-align: center;
  line-height: 1;
`;

export const StyledInputField = styled(InputField)`
  margin-top: ${rem(16)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${rem(20)};
`;
