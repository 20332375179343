import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import { AlignType, JustifyType } from './Dropdown';

const triangleSize = 12;
const triangleBorderSize = 13;
const dropdownWidth = 236;
const topOffset = 8;
const alignmentCenter = dropdownWidth / 2 - triangleSize;
const alignmentLeft = 13;
const alignmentRight = dropdownWidth - triangleSize * 4;
const bottomOffset = triangleSize + 8;

const TriangleMixin = css`
  content: '';
  display: block;
  position: absolute;
  z-index: 2;
  width: 0;
  height: 0;
  border-style: solid;
`;

interface Props {
  align: AlignType;
  justify: JustifyType;
}

export const StyledDropdown = styled.div<Props>`
  ${(props) =>
    (props.justify === 'left' || props.justify === 'center' || props.justify === 'right') &&
    css`
      content: '';
    `}

  position: relative;
  width: 100%;
  z-index: 2;

  ${(props) =>
    props.align === 'bottom' &&
    css`
      &::before,
      &::after {
        transform: translateY(${rem(0 - triangleSize - 4)}) rotate(180deg);
        ${TriangleMixin}
      }

      &::after {
        top: ${rem(0 - bottomOffset - 9)};
        left: ${rem(0)};
        border-width: 0 ${rem(triangleSize)} ${rem(triangleSize)} ${rem(triangleSize)};
        border-color: transparent transparent ${variables.colors.white} transparent;
      }

      &::before {
        top: ${rem(0 - bottomOffset - 8)};
        left: ${rem(-1)};
        border-width: 0 ${rem(triangleBorderSize)} ${rem(triangleSize)} ${rem(triangleBorderSize)};
        border-color: transparent transparent ${variables.colors.mediumGray} transparent;
      }
    `}

  ${(props) =>
    props.align === 'top' &&
    props.justify === 'left' &&
    css`
      &::before,
      &::after {
        transform: translateX(${rem(0 - alignmentLeft)}) translateY(${rem(topOffset + triangleSize)});
        ${TriangleMixin}
      }

      &::after {
        top: ${rem(0 - triangleSize + 1)};
        left: ${rem(alignmentLeft)};
        border-width: 0 ${rem(triangleSize)} ${rem(triangleSize)} ${rem(triangleSize)};
        border-color: transparent transparent ${variables.colors.white} transparent;
      }

      &::before {
        top: ${rem(0 - triangleSize)};
        left: ${rem(alignmentLeft - 1)};
        border-width: 0 ${rem(triangleBorderSize)} ${rem(triangleBorderSize)} ${rem(triangleBorderSize)};
        border-color: transparent transparent ${variables.colors.mediumGray} transparent;
      }
    `}

  ${(props) =>
    props.align === 'top' &&
    (props.justify === 'center' || props.justify === 'right') &&
    css`
      &::before,
      &::after {
        transform: translateX(${rem(0 - alignmentCenter)}) translateY(${rem(topOffset + triangleSize)});
        ${TriangleMixin}
      }

      &::after {
        top: ${rem(0 - triangleSize + 1)};
        left: ${rem(alignmentCenter)};
        border-width: 0 ${rem(triangleSize)} ${rem(triangleSize)} ${rem(triangleSize)};
        border-color: transparent transparent ${variables.colors.white} transparent;
      }

      &::before {
        top: ${rem(0 - triangleSize)};
        left: ${rem(alignmentCenter - 1)};
        border-width: 0 ${rem(triangleBorderSize)} ${rem(triangleBorderSize)} ${rem(triangleBorderSize)};
        border-color: transparent transparent ${variables.colors.mediumGray} transparent;
      }
    `}
`;

export const StyledDropdownContent = styled.div<Props>`
  position: absolute;
  padding: ${rem(variables.size.containerPadding)};
  width: ${rem(dropdownWidth)};
  background-color: ${variables.colors.white};
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(2)};
  box-shadow: 0 ${rem(8)} ${rem(20)} ${rgba(variables.colors.black, 0.15)};
  display: flex;
  justify-content: center;

  ${(props) =>
    props.align === 'bottom' &&
    css`
      transform: translateX(${rem(variables.size.containerPadding)})
        translateY(${rem(0 - bottomOffset - triangleSize - 4)});
      bottom: ${rem(topOffset)};
      right: 0;
    `}

  ${(props) =>
    props.align === 'top' &&
    props.justify === 'left' &&
    css`
      transform: translateX(${rem(0 - alignmentLeft)}) translateY(${rem(topOffset + triangleSize)});
    `}

  ${(props) =>
    props.align === 'top' &&
    props.justify === 'center' &&
    css`
      transform: translateX(${rem(0 - alignmentCenter)}) translateY(${rem(topOffset + triangleSize)});
    `}

  ${(props) =>
    props.align === 'top' &&
    props.justify === 'right' &&
    css`
      transform: translateX(${rem(0 - alignmentRight)}) translateY(${rem(topOffset + triangleSize)});
    `}
`;
