import useTranslation from 'next-translate/useTranslation';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import useUserAgent from '@hooks/useUserAgent';
import webToAppApi from '@api/web-to-app';
import {
  StyledCompleteButton,
  StyledCompleteForm,
  StyledCompleteIcon,
  StyledCompleteText,
  StyledCompleteWrapper,
  StyledHeading,
} from './CompleteForm.styles';
import Validation from '@icons/validation.svg';

interface Props {
  option: OptionType;
  onRouteToSpecificList: () => void;
}

const CompleteForm = ({ option, onRouteToSpecificList }: Props) => {
  const { t } = useTranslation('addToList');
  const { isNativeApp } = useUserAgent();

  return (
    <StyledCompleteForm>
      <StyledHeading variant="h3" label>
        {t('addToList->heading->saved')}
      </StyledHeading>

      <StyledCompleteWrapper>
        <StyledCompleteText size="medium" color="black" type="body">
          {option.label}
        </StyledCompleteText>
        <StyledCompleteIcon svg={Validation} size={16} />
      </StyledCompleteWrapper>

      {!isNativeApp && (
        <Link href={`${paths.MY_LISTS}/${option.value}`}>
          <StyledCompleteButton onClick={() => {}} theme="secondary" type="button">
            {t('addToList->button->saved')}
          </StyledCompleteButton>
        </Link>
      )}

      {isNativeApp && (
        <StyledCompleteButton
          onClick={() => {
            onRouteToSpecificList();
            webToAppApi.actionRouteToSpecificList(option.value);
          }}
          theme="secondary"
          type="button"
        >
          {t('addToList->button->saved')}
        </StyledCompleteButton>
      )}
    </StyledCompleteForm>
  );
};

export default CompleteForm;
