import Config from '@config';
import { ReactNode, useRef, useState } from 'react';
import debounce from '@helpers/debounce';
import resizeListener from '@helpers/resizeListener';
import OutsideClickWatcher from '@utility/OutsideClickWatcher/OutsideClickWatcher';
import useBrowserLayoutEffect from '@helpers/useBrowserLayoutEffect';
import { StyledDropdown, StyledDropdownContent } from './Dropdown.styles';

export type JustifyType = 'center' | 'left' | 'right';
export type AlignType = 'bottom' | 'top';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  align: AlignType;
  forceJustify?: JustifyType;
  closeHandler: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}

const Dropdown = ({ children, forceJustify, isOpen, align = 'top', closeHandler, onMouseOver, onMouseLeave }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [justify, setJustify] = useState<JustifyType>(forceJustify || 'center');

  const justifyHandler = () => {
    const rect = ref?.current?.getBoundingClientRect();

    if (rect && isOpen) {
      setJustify(rect.x < 0 ? 'left' : 'center');
    }
  };

  const debouncedJustify = debounce(() => {
    setJustify('center');
    justifyHandler();
  }, Config.TIMEOUT.dropdownJustifyMs);

  useBrowserLayoutEffect(() => {
    // Do not run any of the effect if forceJustify is unset
    if (!forceJustify) {
      justifyHandler();

      if (isOpen) {
        resizeListener.set(debouncedJustify);
      }
    } else {
      setJustify(forceJustify);
    }
    return () => {
      setJustify('center'); // need to reset to center for justifyHandler to work consistently
      if (!forceJustify) {
        resizeListener.remove(debouncedJustify);
      }
    };
  }, [isOpen]);

  const onOutsideClickHandler = () => {
    closeHandler();
  };

  return isOpen ? (
    <OutsideClickWatcher clickHandler={onOutsideClickHandler}>
      <StyledDropdown
        align={align}
        justify={justify}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        data-testid="dropdown"
      >
        <StyledDropdownContent align={align} justify={justify} ref={ref}>
          {children}
        </StyledDropdownContent>
      </StyledDropdown>
    </OutsideClickWatcher>
  ) : null;
};

export default Dropdown;
